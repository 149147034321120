import request from '@/utils/request'

// 查询结果管理列表
export function listResult(query) {
  return request({
    url: '/work/Result/list',
    method: 'get',
    params: query
  })
}

// 查询结果管理详细
export function getResult(id) {
  return request({
    url: '/work/Result/' + id,
    method: 'get'
  })
}

// 新增结果管理
export function addResult(data) {
  return request({
    url: '/work/Result',
    method: 'post',
    data: data
  })
}

// 修改结果管理
export function updateResult(data) {
  return request({
    url: '/work/Result/update',
    method: 'post',
    data: data
  })
}

// 删除结果管理
export function delResult(id) {
  return request({
    url: '/work/Result/remove/' + id,
    method: 'get'
  })
}

// 导出结果管理
export function exportResult(query) {
  return request({
    url: '/work/Result/export',
    method: 'get',
    params: query
  })
}