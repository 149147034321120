import request from '@/utils/request'

// 查询题干管理列表
export function listStem(query) {
  return request({
    url: '/work/Stem/list',
    method: 'get',
    params: query
  })
}

// 查询题干管理详细
export function getStem(id) {
  return request({
    url: '/work/Stem/' + id,
    method: 'get'
  })
}

// 新增题干管理
export function addStem(data) {
  return request({
    url: '/work/Stem',
    method: 'post',
    data: data
  })
}

// 修改题干管理
export function updateStem(data) {
  return request({
    url: '/work/Stem/update',
    method: 'post',
    data: data
  })
}

// 删除题干管理
export function delStem(id) {
  return request({
    url: '/work/Stem/remove/' + id,
    method: 'get'
  })
}

// 导出题干管理
export function exportStem(query) {
  return request({
    url: '/work/Stem/export',
    method: 'get',
    params: query
  })
}