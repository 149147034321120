<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="主键" prop="id">
        <el-input
          v-model="queryParams.id"
          placeholder="请输入主键"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="题目标题" prop="title">
        <el-input
          v-model="queryParams.title"
          placeholder="请输入题目标题"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="创建人" prop="createBy">
        <el-input
          v-model="queryParams.createBy"
          placeholder="请输入创建人"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
        >新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="success"
          plain
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
        >修改</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          plain
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
        >删除</el-button>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="TopicList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="主键" align="center" prop="id" />
      <el-table-column label="题目标题" align="center" prop="title"/>
      <el-table-column label="副标题" align="center" prop="subtitle" />
      <el-table-column label="图片" align="center" prop="imgs">
        <template slot-scope="scope">
          　　　　<img :src="scope.row.imgs" height="40" width="60" />
          　　</template>
      </el-table-column>
      <el-table-column label="首页图" align="center" prop="banner">
        <template slot-scope="scope">
          　　　　<img :src="scope.row.banner" height="40" width="60" />
          　　</template>
      </el-table-column>

<!--      <el-table-column label="标签" align="center" prop="tags" />-->
<!--      <el-table-column label="about" align="center" prop="about" />-->
      <el-table-column label="描述" align="center" prop="summary" />
      <el-table-column label="点击量" align="center" prop="click" />
<!--      <el-table-column label="备注" align="center" prop="remark" />-->
<!--      <el-table-column label="创建人" align="center" prop="createBy" />-->
<!--      <el-table-column label="创建时间" align="center" prop="createTime" />-->
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
          >修改</el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
          >删除</el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleStemList(scope.row)"
          >题干管理</el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleResultList(scope.row)"
          >结果管理</el-button>
        </template>
      </el-table-column>
    </el-table>
    
    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改题目管理对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="题目标题" prop="title">
          <el-input v-model="form.title" placeholder="请输入题目标题" />
        </el-form-item>
        <el-form-item label="副标题" prop="subtitle">
          <el-input v-model="form.subtitle" placeholder="请输入副标题" />
        </el-form-item>
        <el-form-item label="图片" prop="imgs">
          <el-input v-model="form.imgs" placeholder="和本地上传二选一" />
          <template>
            　<img :src="form.imgs" height="40" width="60" />
          </template>
          <el-upload
              :action="uploadImgUrl"
              :limit="1"
              :on-exceed="handleExceed"
              :on-success="handleAvatarSuccessTopic"
              class="upload-demo"
              multiple
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>

        <el-form-item label="封面图" prop="banner">
          <el-input v-model="form.banner" placeholder="和本地上传二选一" />
          <template>
            　<img :src="form.banner" height="40" width="60" />
          </template>
          <el-upload
              :action="uploadImgUrl"
              :limit="1"
              :on-exceed="handleExceed"
              :on-success="handleAvatarSuccessTopicBanner"
              class="upload-demo"
              multiple
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>

<!--        <el-form-item label="备注" prop="remark">-->
<!--          <el-input v-model="form.remark" placeholder="请输入备注" />-->
<!--        </el-form-item>-->
<!--        <el-form-item label="标签" prop="tags">-->
<!--          <el-input v-model="form.tags" placeholder="请输入标签" />-->
<!--        </el-form-item>-->
        <el-form-item label="描述" prop="summary">
          <el-input v-model="form.summary" placeholder="请输入描述" />
        </el-form-item>
<!--        <el-form-item label="about" prop="about">-->
<!--          <el-input v-model="form.about" placeholder="请输入about" />-->
<!--        </el-form-item>-->

        <el-form-item label="点击量" prop="click">
          <el-input v-model="form.click" placeholder="请输入点击量" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

<!--    题目中的题干管理-->
    <el-dialog
        title="题干管理"
        :visible.sync="dialogVisibleAdConfig"
        width="70%"
        >
      <el-row :gutter="10" class="mb8">
        <el-col :span="1.5">
          <el-button
              type="primary"
              plain
              icon="el-icon-plus"
              size="mini"
              @click="handleAddStem"
          >新增</el-button>
        </el-col>

      </el-row>
    <el-table v-loading="loading" :data="StemList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
<!--      <el-table-column label="主键" align="center" prop="id" />-->

      <el-table-column label="序号" align="center" prop="order" />
      <el-table-column label="标题" align="center" prop="title" />
<!--      <el-table-column label="图片" align="center" prop="img" />-->
      <el-table-column label="图片" align="center" prop="img">
        <template slot-scope="scope">
          　　　　<img :src="scope.row.img" height="40" width="60" />
          　　</template>
      </el-table-column>


      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdateStem(scope.row)"
          >修改</el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="dialogVisibleOptionsHandle(scope.row)"
          >选项管理</el-button>
                      <el-button
                          size="mini"
                          type="text"
                          icon="el-icon-delete"
                          @click="handleDeleteStem(scope.row)"
                      >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    </el-dialog>

    <!-- 添加或修改题干管理对话框 -->
    <el-dialog :title="title" :visible.sync="openStem" width="500px" append-to-body>
      <el-form ref="form" :model="formStem" :rules="rules" label-width="80px">
        <el-form-item label="序号" prop="order">
          <el-input v-model="formStem.order" placeholder="请输入序号" />
        </el-form-item>
        <el-form-item label="内容" prop="title">
          <el-input v-model="formStem.title" placeholder="请输入内容" />
        </el-form-item>

      <el-form-item label="图片" prop="img">
        <el-input v-model="formStem.img" placeholder="和本地上传二选一" />
        <template>
          　<img :src="formStem.img" height="40" width="60" />
        </template>
        <el-upload
            :action="uploadImgUrl"
            :limit="1"
            :on-exceed="handleExceed"
            :on-success="handleAvatarSuccessStem"
            class="upload-demo"
            multiple
        >
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFormStem">确 定</el-button>
        <el-button @click="cancelStem">取 消</el-button>
      </div>
    </el-dialog>

    <!--    题目中的选项管理-->
    <el-dialog
        title="选项管理"
        :visible.sync="dialogVisibleOptions"
        width="70%"
    >
      <el-row :gutter="10" class="mb8">
        <el-col :span="1.5">
          <el-button
              type="primary"
              plain
              icon="el-icon-plus"
              size="mini"
              @click="handleAddOptions"
          >新增</el-button>
        </el-col>

      </el-row>
      <el-table v-loading="loading" :data="OptionsList" @selection-change="handleSelectionChange">

        <el-table-column type="selection" width="55" align="center" />
        <!--        <el-table-column label="主键" align="center" prop="id" />-->
        <el-table-column label="顺序" align="center" prop="order" />
        <el-table-column label="内容" align="center" prop="title" />

        <!--        <el-table-column label="所属题干" align="center" prop="stemId" />-->
        <!--        <el-table-column label="创建人" align="center" prop="createBy" />-->
        <!--        <el-table-column label="创建时间" align="center" prop="createTime" />-->
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click="handleUpdateOptions(scope.row)"
            >修改</el-button>
            <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                @click="handleDeleteOptions(scope.row)"
            >删除</el-button>
          </template>
        </el-table-column>

      </el-table>
    </el-dialog>

    <!-- 添加或修改选项管理对话框 -->
    <el-dialog :title="title" :visible.sync="openOptions" width="500px" append-to-body>
      <el-form ref="formOptions" :model="formOptions" :rules="rules" label-width="80px">
        <el-form-item label="顺序" prop="order">
          <el-input v-model="formOptions.order" placeholder="请输入顺序" />
        </el-form-item>
        <el-form-item label="内容" prop="title">
          <el-input v-model="formOptions.title" placeholder="请输入标题" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFormOptions">确 定</el-button>
        <el-button @click="cancelOptions">取 消</el-button>
      </div>
    </el-dialog>

    <!--    题目中的结果管理-->
    <el-dialog
        title="答案管理"
        :visible.sync="dialogVisibleResult"
        width="70%"
    >
      <el-row :gutter="10" class="mb8">
        <el-col :span="1.5">
          <el-button
              type="primary"
              plain
              icon="el-icon-plus"
              size="mini"
              @click="handleAddResult"
          >新增</el-button>
        </el-col>

      </el-row>
      <el-table v-loading="loading" :data="ResultList" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" align="center" />
<!--        <el-table-column label="题目id" align="center" prop="topicId" />-->
<!--        <el-table-column label="结果" align="center" prop="result" />-->
        <el-table-column label="文字结果" align="center" prop="txtResult" />
<!--        <el-table-column label="图片结果" align="center" prop="ImgResult" />-->
              <el-table-column align="center" label="图片结果" >
                　<template slot-scope="scope">
                　　　　<img :src="scope.row.imgResult" height="240" width="160" />
                　　</template>
              </el-table-column>
        <el-table-column label="选项" align="center" prop="stemIds" />
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">

          <template slot-scope="scope">
            <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click="handleUpdateResult(scope.row)"
            >修改</el-button>

            <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                @click="handleDeleteResult(scope.row)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <!-- 添加或修改结果管理对话框 -->
    <el-dialog :title="title" :visible.sync="openResult" width="500px" append-to-body>
      <el-form ref="formResult" :model="formResult" :rules="rules" label-width="80px">
        <el-form-item label="文字结果" prop="txtResult">
          <el-input v-model="formResult.txtResult" placeholder="请输入结果" />
        </el-form-item>
        <el-form-item label="图片结果" prop="imgResult">
          <el-input v-model="formResult.imgResult" placeholder="和本地上传二选一" />
          <template>
            　<img :src="formResult.imgResult" height="120" width="80" />
          </template>
          <el-upload
              :action="uploadImgUrl"
              :limit="1"
              :on-exceed="handleExceed"
              :on-success="handleAvatarSuccessTopicImgResult"
              class="upload-demo"
              multiple
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="选项" prop="stemIds">
          <el-input v-model="formResult.stemIds" placeholder="请输入选项" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFormResult">确 定</el-button>
        <el-button @click="cancelResult">取 消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { listTopic, getTopic, delTopic, addTopic, updateTopic } from "@/api/work/Topic";
import { listStem, getStem, delStem, addStem, updateStem } from "@/api/work/Stem";
import { listOptions, getOptions, delOptions, addOptions, updateOptions } from "@/api/work/Options";
import { listResult, getResult, delResult, addResult, updateResult } from "@/api/work/Result";

export default {
  name: "Topic",
  components: {
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 题目管理表格数据
      TopicList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 是否显示弹出层
      openStem: false,
      // 是否显示弹出层
      openResult: false,
      // 是否显示弹出层
      openOptions: false,
      // 主键字典
      idOptions: [],
      // 题干管理表格数据
      StemList: [],
      // 答案管理表格数据
      ResultList: [],
      //选项表格数据
      OptionsList:[],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        id: null,
        title: null,
        createBy: null,
      },
      //广告位配置弹窗
      dialogVisibleAdConfig: false,
      dialogVisibleOptions:false,
      dialogVisibleResult:false,
      // 表单参数
      form: {},
      // 表单参数
      formStem: {},
      // 表单参数
      formOptions: {},
      // 表单参数
      formResult: {},
      //临时点击TopicId
      tempTopicId:'',
      //临时点击stemId
      tempStemId:'',
      uploadImgUrl: "https://api.sky1998.cn" + "/common/file/uploadToTencentOss", // 上传的图片服务器地址
      // 表单校验
      rules: {
      }
    };
  },
  created() {
    this.getList();
    this.getDicts("${column.dictType}").then(response => {
      this.idOptions = response.data;
    });
  },
  methods: {
    /** 查询题目管理列表 */
    getList() {
      this.loading = true;
      listTopic(this.queryParams).then(response => {
        this.TopicList = response.datas;
        this.total = response.total;
        this.loading = false;
      });
    },
    // 主键字典翻译
    idFormat(row, column) {
      return this.selectDictLabel(this.idOptions, row.id);
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 取消题干按钮
    cancelStem() {
      this.openStem = false;
      this.reset();
    },
    // 取消选项按钮
    cancelOptions() {
      this.openOptions = false;
      this.reset();
    },
    // 取消结果按钮
    cancelResult() {
      this.openResult = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        title: null,
        imgs: null,
        remark: null,
        createBy: null,
        createTime: null,
        tags: null,
        summary: null,
        subtitle: null,
        click: null
      };
      this.resetForm("form");

      this.formOptions = {
        id: null,
        title: null,
        imgs: null,
        remark: null,
        createBy: null,
        createTime: null,
        tags: null,
        summary: null,
        subtitle: null,
        click: null
      };
      this.resetForm("formOptions");


      this.formStem = {
        id: null,
        title: null,
        imgs: null,
        remark: null,
        createBy: null,
        createTime: null,
        tags: null,
        summary: null,
        subtitle: null,
        click: null
      };
      this.resetForm("formStem");

      this.formResult = {
        id: null,
        title: null,
        imgs: null,
        remark: null,
        createBy: null,
        createTime: null,
        tags: null,
        summary: null,
        subtitle: null,
        click: null
      };
      this.resetForm("formResult");

    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    handleAvatarSuccessTopic(res, file) {
      this.form.imgs=res.datas

    },
    handleAvatarSuccessStem(res, file) {
      this.formStem.img=res.datas

    },
    handleAvatarSuccessTopicImgResult(res, file) {
      this.formResult.imgResult=res.datas

    },
    handleAvatarSuccessTopicBanner(res, file) {
      this.form.banner=res.datas

    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加题目管理";
    },
    /** 新增按钮操作 */
    handleAddStem() {
      this.reset();
      this.openStem = true;
      this.title = "添加题干管理";
    },
    /** 新增按钮操作 */
    handleAddResult() {
      this.reset();
      this.openResult = true;
      this.title = "添加结果管理";
    },
    /** 新增按钮操作 */
    handleAddOptions() {
      this.reset();
      this.openOptions = true;
      this.title = "添加选项管理";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getTopic(id).then(response => {
        this.form = response.datas;
        this.open = true;
        this.title = "修改题目管理";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            //about不再手输-默认和副标题一致
            this.form.about=this.form.subtitle
            updateTopic(this.form).then(response => {
              this.$modal.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            //about不再手输-默认和副标题一致
            this.form.about=this.form.subtitle
            addTopic(this.form).then(response => {
              this.$modal.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm('是否确认删除题目管理编号为"' + ids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delTopic(ids);
        }).then(() => {
          this.getList();
          this.$modal.msgSuccess("删除成功");
        })
    },
    //--------------------------------------------------
    /** 获取该题目题干按钮操作 */
    handleStemList(row) {
      const id = row.id;
      this.tempTopicId=id;
      const param={"topicId":id}
      listStem(param).then(response => {
        this.StemList = response.datas;
        this.dialogVisibleAdConfig=true
      });


    },
    /** 获取该题目答案按钮操作 */
    handleResultList(row) {
      const id = row.id;
      const param={"topicId":id}
      this.tempTopicId=id;
      listResult(param).then(response => {
        this.ResultList = response.datas;
        this.dialogVisibleResult=true
      });


    },
    /** 提交按钮 题干 */
    submitFormStem() {
          if (this.formStem.id != null) {
            updateStem(this.formStem).then(response => {
              this.$modal.msgSuccess("修改成功");
              this.openStem = false;
              const param={"topicId":this.formStem.topicId}
              //  this.handleStemList(param);
              listStem(param).then(response => {
                this.StemList = response.datas;
                this.dialogVisibleAdConfig=true
              });
            });
          } else {
            this.formStem.topicId=this.tempTopicId
            addStem(this.formStem).then(response => {
              this.$modal.msgSuccess("新增成功");
              this.openStem = false;
              const param={"topicId":this.formStem.topicId}
            //  this.handleStemList(param);
              listStem(param).then(response => {
                this.StemList = response.datas;
                this.dialogVisibleAdConfig=true
              });
            });
          }

    },
    /** 提交按钮 选项 */
    submitFormOptions() {
      if (this.formOptions.id != null) {
        updateOptions(this.formOptions).then(response => {
          this.$modal.msgSuccess("修改成功");
          this.openOptions = false;
          // this.getList();
          const param={"stemId":this.formOptions.stemId}
          listOptions(param).then(response => {
            this.OptionsList = response.datas;
          });

        });
      } else {
        this.formOptions.stemId=this.tempStemId
        addOptions(this.formOptions).then(response => {
          this.$modal.msgSuccess("新增成功");
          this.openOptions = false;
          // this.getList();
          const param={"stemId":this.formOptions.stemId}
          listOptions(param).then(response => {
            this.OptionsList = response.datas;
          });
        });
      }
    },
    /** 提交按钮 答案*/
    submitFormResult() {
      this.$refs["formResult"].validate(valid => {
        if (valid) {
          if (this.formResult.id != null) {
            updateResult(this.formResult).then(response => {
              this.$modal.msgSuccess("修改成功");
              this.openResult = false;
              //this.getList();
              const param={"topicId":this.tempTopicId}
              listResult(param).then(response => {
                this.ResultList = response.datas;
                this.dialogVisibleResult=true
              });
            });
          } else {
            this.formResult.topicId=this.tempTopicId
            addResult(this.formResult).then(response => {
              this.$modal.msgSuccess("新增成功");
              this.openResult = false;
              const param={"topicId":this.tempTopicId}
              listResult(param).then(response => {
                this.ResultList = response.datas;
                this.dialogVisibleResult=true
              });
            });
          }
        }
      });
    },
    /** 修改题干按钮操作 */
    handleUpdateStem(row) {
      this.reset();
      const id = row.id || this.ids
      getStem(id).then(response => {
        this.formStem = response.datas;
        this.openStem = true;
        this.title = "修改题干管理";
      });
    },
    /** 修改答案按钮操作 */
    handleUpdateResult(row) {
      this.reset();
      const id = row.id || this.ids
      getResult(id).then(response => {
        this.formResult = response.datas;
        this.openResult = true;
        this.title = "修改答案管理";
      });
    },
    /** 删除按钮操作 */
    handleDeleteStem(row) {
      const ids = row.id || this.ids;
      this.$confirm('是否确认删除题干管理编号为"' + ids + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function() {
        return delStem(ids);
      }).then(() => {
        this.getList();
        this.$modal.msgSuccess("删除成功");
      })
    },
    //选项弹窗
    dialogVisibleOptionsHandle(row){
      this.tempStemId=row.id
      console.log("this.tempStemId",this.tempStemId)
      const param={"stemId":row.id}
      listOptions(param).then(response => {
        this.OptionsList = response.datas;
      });
      this.dialogVisibleOptions=true

    },
    /** 修改按钮操作 */
    handleUpdateOptions(row) {
      this.reset();
      const id = row.id || this.ids
      getOptions(id).then(response => {
        this.formOptions = response.datas;
        this.openOptions = true;
        this.title = "修改选项管理";
      });
    },

    /** 删除按钮操作 */
    handleDeleteOptions(row) {
      const ids = row.id || this.ids;
      this.$confirm('是否确认删除选项管理编号为"' + ids + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function() {
        return delOptions(ids);
      }).then(() => {
        this.getList();
        this.$modal.msgSuccess("删除成功");
      })
    },

    /** 删除答案按钮操作 */
    handleDeleteResult(row) {
      const ids = row.id || this.ids;
      this.$confirm('是否确认删除选项管理编号为"' + ids + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function() {
        return delResult(ids);
      }).then(() => {
        this.getList();
        this.$modal.msgSuccess("删除成功");
      })
    },
  },

};
</script>
