import request from '@/utils/request'

// 查询题目管理列表
export function listTopic(query) {
  return request({
    url: '/work/Topic/list',
    method: 'get',
    params: query
  })
}

// 查询题目管理详细
export function getTopic(id) {
  return request({
    url: '/work/Topic/' + id,
    method: 'get'
  })
}

// 新增题目管理
export function addTopic(data) {
  return request({
    url: '/work/Topic',
    method: 'post',
    data: data
  })
}

// 修改题目管理
export function updateTopic(data) {
  return request({
    url: '/work/Topic/update',
    method: 'post',
    data: data
  })
}

// 删除题目管理
export function delTopic(id) {
  return request({
    url: '/work/Topic/remove/' + id,
    method: 'get'
  })
}

// 导出题目管理
export function exportTopic(query) {
  return request({
    url: '/work/Topic/export',
    method: 'get',
    params: query
  })
}