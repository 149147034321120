import request from '@/utils/request'

// 查询选项管理列表
export function listOptions(query) {
  return request({
    url: '/work/Options/list',
    method: 'get',
    params: query
  })
}

// 查询选项管理详细
export function getOptions(id) {
  return request({
    url: '/work/Options/' + id,
    method: 'get'
  })
}

// 新增选项管理
export function addOptions(data) {
  return request({
    url: '/work/Options',
    method: 'post',
    data: data
  })
}

// 修改选项管理
export function updateOptions(data) {
  return request({
    url: '/work/Options/update',
    method: 'post',
    data: data
  })
}

// 删除选项管理
export function delOptions(id) {
  return request({
    url: '/work/Options/remove/' + id,
    method: 'get'
  })
}

// 导出选项管理
export function exportOptions(query) {
  return request({
    url: '/work/Options/export',
    method: 'get',
    params: query
  })
}